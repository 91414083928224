<template>
    <v-card flat>
        
        <v-card flat>
            <v-toolbar dense flat>
                <v-toolbar-title>Transaction Items</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeView()">
                    <v-icon>fas fa-backspace</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout wrap>
                    <v-flex xs12>
                        <Search v-on:gSearch="updateSearch" v-on:add="addItem = true"></Search>
                    </v-flex>

                    <div style="max-height: 700px; overflow: auto; width: 100%;">
                        <itemList v-for="itemMinus in showItems" 
                                  :keys="itemMinus.id" 
                                  v-bind:item="itemMinus"></itemList>
                    </div>

                </v-layout>
            </v-card-text>
        </v-card>

        <addItem v-if="addItem" 
                 v-bind:currency="currency"
                 v-bind:placeSold="placeSold"
                 v-on:ItemToAdd="returnNewItem($event)" 
                 v-on:show="adjustShow($event)"
                 v-on:getPlaceSold="returnPlaceSold($event)"></addItem>


        <!--Edit Item-->
        <v-layout row justify-center>
            <v-dialog v-model="editItems" persistent scrollable max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Edit Item</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click.native="editItemCheck($v.editItemTemp)">
                            <v-icon color="green">fas fa-check</v-icon>
                        </v-btn>
                        <v-btn icon @click.native="editItems = false">
                            <v-icon color="red">fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field label="Item Name"
                                                  v-if="itemNameMain"
                                                  v-model="editItemTemp.itemName"
                                                  :error-messages="ItemNameErrors"
                                                  @input="$v.editItemTemp.itemName.$touch()"
                                                  @blur="$v.editItemTemp.itemName.$touch()">
                                        <template slot="append-outer">
                                            <v-tooltip left>
                                                <v-btn v-on:click.prevent="itemNameMain = !itemNameMain"
                                                       slot="activator"
                                                       small
                                                       fab
                                                       text
                                                       middle
                                                       right>
                                                    <v-icon>fas fa-list</v-icon>
                                                </v-btn>
                                                <span>Items</span>
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-autocomplete v-if="!itemNameMain"
                                                    v-model="editItemTemp.itemName"
                                                    :items="items"
                                                    chips
                                                    label="Item Name"
                                                    :error-messages="ItemNameErrors"
                                                    @input="$v.editItemTemp.itemName.$touch(), updateFieldsForSelected()"
                                                    @blur="$v.editItemTemp.itemName.$touch()"
                                                    clearable
                                                    item-text="itemName"
                                                    item-value="itemName">
                                        <template slot="append-outer">
                                            <v-tooltip left>
                                                <v-btn v-on:click.prevent="itemNameMain = !itemNameMain"
                                                       slot="activator"
                                                       small
                                                       fab
                                                       text
                                                       middle
                                                       right>
                                                    <v-icon>fas fa-plus</v-icon>
                                                </v-btn>
                                                <span>Add Item</span>
                                            </v-tooltip>
                                        </template>
                                        <template slot="selection"
                                                  slot-scope="data">
                                            <v-chip :selected="data.selected"
                                                    class="chip--select-multi">
                                                {{ data.item.itemName }}
                                            </v-chip>
                                        </template>
                                        <template slot="item"
                                                  slot-scope="data">

                                            <template>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.itemName"></v-list-item-title>
                                                    <v-list-item-sub-title>{{data.item.currency}} {{data.item.itemPrice}}</v-list-item-sub-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field label="Item Price" type="number" min="1"
                                                  v-model="editItemTemp.itemPrice"
                                                  :error-messages="ItemPriceErrors"
                                                  @input="$v.editItemTemp.itemPrice.$touch()"
                                                  @blur="$v.editItemTemp.itemPrice.$touch()"></v-text-field>
                                </v-flex>
                                <v-flex xs6>
                                    <v-select v-bind:items="curriencies"
                                              label="Currency"
                                              v-model="editItemTemp.currency"
                                              single-line
                                              :error-messages="currencyErrors"
                                              @input="$v.editItemTemp.currency.$touch()"
                                              @blur="$v.editItemTemp.currency.$touch()"></v-select>
                                </v-flex>
                                <v-flex xs12>
                                    <v-checkbox label="Add Tax to item?"
                                                v-model="editItemTemp.isTaxed"></v-checkbox>
                                </v-flex>
                                <v-flex xs12 v-if="editItemTemp.isTaxed">
                                    <v-text-field label="Total WIth Tax" type="number"
                                                  v-model="editItemTemp.taxAmount"></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-dialog>
        </v-layout>
        <!--End Of edit Item-->

        <!--Delete Item-->
        <v-layout row justify-center>
            <v-dialog v-model="deleteDialog" max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete</v-card-title>
                    <v-card-text>Are you sure you want to delete <b>"{{deleteItemTemp.itemName}}"</b>?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text="text" @click.native="deleteDialog = false">No</v-btn>
                        <v-btn color="green darken-1" text="text" @click.native="deleteItemAccept()">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
        <!--End Of Delete Item-->

    </v-card>
</template>

<script>
  import { validationMixin } from 'vuelidate'
    import { required, minValue } from 'vuelidate/lib/validators'

    import { globalFunctions } from '../../javascript/globalFunctions'

  export default {
    mixins: [validationMixin],
    components: {
        Search: () => import('../search/searchWithAddButton.vue'),
        addItem: () => import('./addTransactionItem.vue'),
        itemList: () => import('./itemList.vue')
    },
    props: ["itype", "itemIds", "show", "currency", "placeSold"],
    data() {
      return {
        curriencies: [],
        addItem: false,
        editItems: false,
        deleteDialog: false,
        ItemsNames: [],
        ItemsSearch: '',
        itemNameMain: false,
        itemTemp: {
            id: 0,
            itemName: '',
            itemPrice: '',
            currency: '',
            category: '',
            quantity: '',
            isTaxed: false,
            taxAmount: '',
            subtotal: '',
            total: '',
            dateChanged: '',

            objectState: 'New',
            show: false
        },
        editItemTemp: {
          id: 0,
          itemName: '',
          itemPrice: '',
          currency: '',
          isTaxed: false,
          taxAmount: '',

          dateChanged: '',

          objectState: '',
          show: false
        },
        deleteItemTemp: {
          id: 0,
          itemName: '',
          itemPrice: '',
          currency: '',
          isTaxed: false,
          taxAmount: '',

          dateChanged: '',

          objectState: '',
          show: false
          },
        listIds: [],
        items: [],
        searchItems: [],
        showItems: []
      }
    },
    computed: {
      ItemNameErrors() {
        const errors = []
        if (!this.$v.itemTemp.itemName.$dirty || !this.$v.editItemTemp.itemName.$dirty) return errors
        !this.$v.itemTemp.itemName.required && !this.$v.editItemTemp.itemName.required && errors.push('Item Name is required')
        return errors
      },
      ItemPriceErrors() {
        const errors = []
        if (!this.$v.itemTemp.itemPrice.$dirty || !this.$v.editItemTemp.itemPrice.$dirty) return errors
        !this.$v.itemTemp.itemPrice.minValue && !this.$v.editItemTemp.itemPrice.minValue && errors.push('Item Price must be at least 1')
        return errors
      },
      currencyErrors() {
        const errors = []
        if (!this.$v.itemTemp.currency.$dirty || !this.$v.editItemTemp.currency.$dirty) return errors
        !this.$v.itemTemp.currency.required && !this.$v.editItemTemp.currency.required && errors.push('Currency is required')
        return errors
      }
    },
    methods: {
      //itemCheck: function ($v) {
      //  $v.$touch(); //Triggers Error Messages

      //  if (this.itemTemp.itemPrice < 0 || !globalFunctions.isIntOrFloat(this.itemTemp.itemPrice) || this.itemTemp.itemPrice === "") { this.itemTemp.itemPrice = 0 }

      //  if ($v.itemName.$invalid || $v.itemPrice.$invalid || $v.currency.$invalid) {
      //    return false
      //  }
      //  return true
      //},
      //addItemCheck: function ($v) {
      //  if (this.itemCheck($v)) {
      //    this.addItemToItems()

      //    $v.$reset()
      //  }
      //},
      //addItemToItems: function () {
      //  //Add Item to Transaction Items

      //  let newItem = JSON.parse(JSON.stringify(this.itemTemp))

      //  newItem.id = globalFunctions.getNextIdForObject(this.items)

      //  this.returnItem(newItem)
      //  this.showItems.push(newItem)

      //  this.addItem = false

      //  this.doesItemExist()

      //  this.resetItemForm()
      //},
      //resetItemForm: function () {
      //  this.itemTemp.itemName = ''
      //  this.itemTemp.itemPrice = 0
      //  this.itemTemp.currency = ''
      //  this.itemTemp.isTaxed = false
      //  this.itemTemp.taxAmount = 0
      //},
      //doesItemExist: function () {
      //  if (this.items.length !== 0) {
      //    for (let i = 0; i < this.items.length; i++) {
      //      if (this.items[i].itemName === this.itemTemp.itemName) {

      //        /////////////////////////////////////////////////////////////
      //        //Check All other values to see if they are the same
      //        /////////////////////////////////////////////////////////////

      //        if (this.items[i].itemPrice !== this.itemTemp.itemPrice) {

      //          ///////////////////////////////////////////////////////////////////
      //          //Create New Item To Represent Update,
      //          //Keep Old Data As To Not Cause Problems To Past Transactions
      //          ///////////////////////////////////////////////////////////////////

      //          this.addNewItem()
      //          return
      //        }

      //      }

      //      if (i === (this.items.length -1)) {
      //        this.addNewItem()
      //        return
      //      }
      //    }
      //  }
      //  else {
      //    this.addNewItem()
      //  }

      //},
      //addNewItem: function () {
      //  this.itemTemp.dateChanged = globalFunctions.getCurrentDate()

      //  let newEntry = JSON.parse(JSON.stringify(this.itemTemp))

      //  newEntry.id = globalFunctions.getNextIdForObject(this.items)

      //  this.$store.commit('addNewItemToStore', newEntry)
      //},
        addToItemsList: function (item) {
            this.listIds.push(item)
            this.showItems.push(item)
        },
        returnPlaceSold: function (place) {
            this.PlaceSold = place

            this.$emit('placeSold', place)
        },
        returnNewItem: function (item) {
            this.addToItemsList(item)
            this.$emit('ItemToAdd', item)
      },
      closeView: function () {
          this.$emit('show', false)
      },
      editItem: function (item) {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].id === parseInt(item)) {
                let editEntry = globalFunctions.stringify(this.items[i])
            this.editItemTemp = editEntry
            break
          }
        }

        this.editItems = true
      },
      editItemCheck: function ($v) {
        if (this.itemCheck($v)) {
          this.editItemTemp.objectState = "Update"

          this.$store.commit('updateIteminStore', this.editItemTemp)

          this.updateItemList()

          this.editItems = false

          this.returnItem(this.editItemTemp)
        }
      },
      updateItemList: function () {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].id === this.editItemTemp.id) {
              this.items[i] = globalFunctions.stringify(this.editItemTemp)
          }
        }
      },
      deleteItem: function (item) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i] === item) {
            this.deleteItemTemp = this.items[i]
            break
          }
        }

        this.deleteDialog = true
      },
      deleteItemAccept: function () {

      },
      getSearchItems: function () {
        let searchItems

        searchItems = JSON.parse(JSON.stringify(this.itemIds))

          this.showItems = []

        //Check if Search is empty  
        if (this.ItemsSearch === '' || this.ItemsSearch === null) {
          //Get All Items from item
            this.showItems = globalFunctions.stringify(searchItems)
          return
        }

        //Remove Transactions that does not meet search criteria 
          for (let i = 0; i < searchItems.length; i++) {
              if (searchItems[i].itemName.toLowerCase().indexOf(this.ItemsSearch.toLowerCase()) !== -1) {
                  this.showItems.push(searchItems[i])
          }
        }
      },
      updateSearch: function (search) {
        this.ItemsSearch = search

        this.getSearchItems()
      },
      getAvailableCurriencies: function () {
        let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.curriencies.push(cur[i])
          }
        }
      },
      updateFieldsForSelected: function () {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].itemName === this.itemTemp.itemName) {
            this.itemTemp.id = this.items[i].id
            this.itemTemp.itemPrice = this.items[i].itemPrice
            this.itemTemp.currency = this.items[i].currency
            this.itemTemp.category = this.items[i].category
            this.itemTemp.isTaxed = this.items[i].isTaxed
            this.itemTemp.taxAmount = this.items[i].taxAmount

            break
          }
          else if (this.items[i].itemName === this.editItemTemp.itemName) {
            this.editItemTemp.id = this.items[i].id
            this.editItemTemp.itemPrice = this.items[i].itemPrice
            this.editItemTemp.currency = this.items[i].currency
            this.editItemTemp.category = this.items[i].category
            this.editItemTemp.isTaxed = this.items[i].isTaxed
            this.editItemTemp.taxAmount = this.items[i].taxAmount

            break
          }
        }
      },
      //getItems: function () {
      //  for (let i = 0; i < this.listIds.length; i++) {
      //    for (let x = 0; x < this.items.length; x++) {
      //      if (this.items[x].id === this.listIds[i].id) {
      //        this.showItems.push(this.items[x])
      //        break
      //      }
      //    }
      //  }
      //},
      adjustShow: function (data) {
         this.addItem = data
        },
        addItemsToList: function () {
            if (this.itemIds.length === 0) {
                return
            }

            this.listIds.push(this.itemIds)
        },
        addItemsToShow() {
            if (this.itemIds.length === 0) {
                return
            }

            for (let i = 0; i < this.itemIds.length; i++) {
                this.showItems.push(this.itemIds[i])
            }
        }
    },
    beforeMount() {
      this.items = this.$store.state.Items
      this.addItemsToShow()
      //this.getItems()
    },
    validations: {
      itemTemp: {
        itemName: {
          required
        },
        itemPrice: {
          required,
          minValue: minValue(1)
        },
        currency: {
          required
        }

      },
      editItemTemp: {
        itemName: {
          required
        },
        itemPrice: {
          required,
          minValue: minValue(1)
        },
        currency: {
          required
        }

      }
    }
  }
</script>

<style scoped>
  .v-divider {
    flex-basis: 1px;
  }
</style>
